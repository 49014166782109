import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ColumnSetting {
  header: string;
  isVisible: boolean;
}

interface PrefencesState {
  columns: ColumnSetting[];
}

export const initialState: PrefencesState = {
  columns: [
    {
      header: 'LM',
      isVisible: true,
    },
    {
      header: 'RP',
      isVisible: true,
    },
    {
      header: 'Price',
      isVisible: true,
    },
    {
      header: 'Batch',
      isVisible: true,
    },
    {
      header: 'Tags',
      isVisible: true,
    },
    {
      header: 'Date Added',
      isVisible: true,
    },
    {
      header: 'PC',
      isVisible: true,
    },
    {
      header: 'Type',
      isVisible: true,
    },
    {
      header: 'Status',
      isVisible: true,
    },
    {
      header: 'Alerts',
      isVisible: true,
    },
    {
      header: 'Link Stats',
      isVisible: true,
    },
    {
      header: 'Url',
      isVisible: true,
    },
    {
      header: 'Landing',
      isVisible: true,
    },
    {
      header: 'Anchor',
      isVisible: true,
    },
    {
      header: 'Notes',
      isVisible: true,
    },
    {
      header: 'GI',
      isVisible: true,
    },
    // {
    //   header: 'GC',
    //   isVisible: true,
    // },
    {
      header: 'Rel',
      isVisible: true,
    },
    {
      header: 'IP',
      isVisible: true,
    },
    {
      header: 'TLD',
      isVisible: true,
    },
    {
      header: 'EFL',
      isVisible: true,
    },
  ],
};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setColumns: (state, action: PayloadAction<ColumnSetting[]>) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    resetColumns: (state) => {
      return {
        ...state,
        columns: initialState.columns,
      };
    },
  },
});

export const { setColumns, resetColumns } = preferencesSlice.actions;

export const selectColumns = (state: RootState) => state.preferences.columns;
