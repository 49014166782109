import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppButton, AppInput } from '@backlinkit/shared';

export type NoteForm = {
  notes: '';
};

export const noteFormDataDefaultValues: NoteForm = {
  notes: '',
};

const noteFormDataSchema = yup.object({
  notes: yup.string(),
});

type NoteFormProps = {
  noteValue?: string;
  onSubmit: (notes: string) => void;
};

const NoteFormComponent: React.FC<NoteFormProps> = ({ noteValue, onSubmit }) => {
  const {
    control,
    formState: { isValid, errors },
    getValues,
  } = useForm<NoteForm>({
    defaultValues: noteValue ? ({ notes: noteValue } as NoteForm) : noteFormDataDefaultValues,
    resolver: yupResolver(noteFormDataSchema),
    mode: 'onChange',
  });

  const handleFormSubmit = (formData?: NoteForm) => {
    if (formData) {
      onSubmit(formData.notes);
    }
  };

  return (
    <Flex flexDirection={'column'} flex={1}>
      <form>
        <AppInput<NoteForm>
          mt={6}
          control={control}
          error={errors.notes}
          label="Notes"
          name="notes"
          placeHolder={'Notes'}
          textArea={true}
          flex={1}
        />

        <AppButton
          mt={8}
          w={'full'}
          isDisabled={!isValid}
          onClick={() => {
            handleFormSubmit(getValues());
          }}
        >
          {'Save'}
        </AppButton>
      </form>
    </Flex>
  );
};

export default NoteFormComponent;
