import React from 'react';
import LinkTypeCell from '../../../components/backlinks/components/cells/link-type-cell';
import LinkStatusCell from '../../../components/backlinks/components/cells/link-status-cell';
import UrlCell from '../../../components/backlinks/components/cells/url-cell';
import LandinPageCell from '../../../components/backlinks/components/cells/landing-page-cell';
import GoogleIndexCell from '../../../components/backlinks/components/cells/google-index-cell';
import GoogleCacheCell from '../../../components/backlinks/components/cells/google-cache-cell';
import RelCell from '../../../components/backlinks/components/cells/rel-cell';
import {
  AppButton,
  AppColors,
  AppDataTable,
  ColumnDefinitionType,
  ExternalBacklink,
} from '@backlinkit/shared';
import SingleAnchorCell from '../../../components/backlinks/components/cells/single-anchor-cell';
import { FaCheckDouble, FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { Tag, TagLabel } from '@chakra-ui/react';
import SingleLandingPageCell from '../../../components/backlinks/components/cells/single-landing-page-cell';
import { SingleErrorCountCell } from '../../../components/backlinks/components/cells/single-error-count-cell';
import SingleRelCell from '../../../components/backlinks/components/cells/single-rel-cell';

type ExternalReportTableProps = {
  data: ExternalBacklink[];
  markBacklinkFixed?: (backlinkId: string) => void;
  markBacklinkUnFixed?: (backlinkId: string) => void;
};

const ExternalReportTable: React.FC<ExternalReportTableProps> = ({
  data = [],
  markBacklinkFixed,
  markBacklinkUnFixed,
}) => {
  const backlinkColumns: ColumnDefinitionType<ExternalBacklink>[] = [
    {
      key: 'custom',
      header: 'Fixed',
      headerMeta: 'URL Marked as fixed by external user',
      headerSortable: true,
      isVisible: true,
      render: (item) => {
        if (item.isFixed) {
          return (
            <Tag borderRadius="full" colorScheme={'green'}>
              <TagLabel>
                <FaRegCheckCircle color="white" />
              </TagLabel>
            </Tag>
          );
        } else {
          return (
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>
                <FaRegTimesCircle color="white" />
              </TagLabel>
            </Tag>
          );
        }
      },
    },
    {
      key: 'custom',
      header: 'Type',
      headerMeta: 'We track 3 link types; text link, image and redirect.',
      headerSortable: true,
      isVisible: true,
      render: (item) => {
        return <LinkTypeCell linkType={item.linkType} />;
      },
    },
    {
      key: 'custom',
      headerSortable: true,
      header: 'Status',
      headerMeta: 'Indicates the HTTP status code of a link',
      render: (item) => (
        <LinkStatusCell linkStatus={item.linkStatus} robotsIndexStatus={item.robotsIndexStatus} />
      ),
    },
    {
      key: 'custom',
      header: 'Errors',
      headerMeta: 'Shows error messages related to the specific link.',
      headerSortable: false,
      render: (item) => <SingleErrorCountCell item={item} />,
    },
    {
      key: 'url',
      header: 'Url',
      headerMeta: 'Indicates the referring page your link is located on.',
      headerSortable: true,
      columnSearchable: true,
      cellDataClickable: true,
      render: (item) => <UrlCell url={item.url} />,
    },
    {
      key: 'expectedLandingPage',
      headerSortable: true,
      header: 'Expected Landing',
      headerMeta: 'The Expected Landing Page',
      columnSearchable: true,
      render: (item) => <SingleLandingPageCell landingPage={item.expectedLandingPage} />,
    },
    {
      key: 'landingPage',
      headerSortable: true,
      header: 'Landing',
      headerMeta: 'The destination URL your link points to.',
      columnSearchable: true,
      render: (item) => (
        <LandinPageCell
          url={item.url}
          landingPage={item.landingPage}
          linkStatus={item.linkStatus}
        />
      ),
    },
    {
      key: 'expectedAnchor',
      headerSortable: true,
      header: 'Expected Anchor',
      headerMeta: 'The expected anchor text',
      columnSearchable: true,
      render: (item) => (
        <SingleAnchorCell
          linkType={item.linkType}
          anchor={item.expectedAnchor ? item.expectedAnchor : 'Left blank'}
        />
      ),
    },
    {
      key: 'anchor',
      headerSortable: true,
      header: 'Anchor',
      headerMeta:
        'The anchor text for text links, empty anchor for image links and the URL for redirects.',
      columnSearchable: true,
      render: (item) => (
        <SingleAnchorCell
          linkType={item.linkType}
          anchor={item.anchor ? item.anchor : 'Not Found'}
        />
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'GI',
      headerMeta:
        'Google index indicator; Green - Page indexed, Orange - Domain indexed - Transparent - Not index',
      cellDataClickable: true,
      render: (item) => (
        <GoogleIndexCell
          url={item.url}
          hasDomainIndex={item?.hasDomainIndex}
          hasPageIndex={item?.hasPageIndex}
          googleCheckDate={new Date()}
        />
      ),
    },
    // {
    //   key: 'custom',
    //   headerSortable: false,
    //   cellDataClickable: true,
    //   header: 'GC',
    //   headerMeta: ' Google cache; Google icon - URL is cached, Error icon - Url not cached.',
    //   render: (item) => (
    //     <GoogleCacheCell
    //       url={item.url}
    //       cacheState={item?.cacheState}
    //       cacheDate={item?.cacheCreatedDate}
    //       googleCheckDate={new Date()}
    //       domainMeta={[]}
    //     />
    //   ),
    // },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Expected Rel',
      headerMeta:
        'The “rel” attribute for the specific link - follow, nofollow (ugc and sponsored).',
      render: (item) => <SingleRelCell relValue={item.expectedRelValue} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Rel',
      headerMeta:
        'The “rel” attribute for the specific link - follow, nofollow (ugc and sponsored).',
      render: (item) => <RelCell relStatus={item.relStatus} relValue={item.relValue} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      cellDataClickable: false,
      render: (item) => {
        return (
          <>
            {markBacklinkFixed && (
              <AppButton
                bgColor={'white'}
                border={`1px solid ${AppColors.appBorder}`}
                variant={'solid'}
                size="xs"
                borderRadius="full"
                onClick={() => {
                  markBacklinkFixed && markBacklinkFixed(item.backlinkId);
                }}
                leftIcon={<FaCheckDouble />}
              >
                Validate Fix
              </AppButton>
            )}
            {markBacklinkUnFixed && (
              <AppButton
                bgColor={'white'}
                border={`1px solid ${AppColors.appBorder}`}
                variant={'solid'}
                size="xs"
                borderRadius="full"
                onClick={() => {
                  markBacklinkUnFixed && markBacklinkUnFixed(item.backlinkId);
                }}
                leftIcon={<FaRegTimesCircle />}
              >
                Request another look
              </AppButton>
            )}
          </>
        );
      },
    },
  ];

  return (
    <AppDataTable
      data={data}
      noDataMessage={'No links added, start by adding some!'}
      columns={backlinkColumns}
      selectableRows={false}
      searchBar={false}
      showColumnVisibility={false}
    />
  );
};

export default ExternalReportTable;
