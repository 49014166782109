import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppButton, SelectOption } from '@backlinkit/shared';
import { BaseFormProps } from './base-forms';
import { Alert, AlertIcon, AlertTitle, Flex, Stack } from '@chakra-ui/react';
import { selectDomain } from '../../store/slices/domain.slice';
import BacklinkBatchFormFields from './backlink-batch-form-fields';
import { useAppSelector } from '../../store/store';

export type BacklinkBatchFormData = {
  id?: string;
  domainId: string;
  name: string;
  price?: number;
  currency?: string;
  notes?: string;
  batchTypeId?: string;
  nicheTypeId?: string;
  tagIds?: string[];
};

export const backlinkBatchFormDefaultValues: BacklinkBatchFormData = {
  domainId: '',
  name: '',
  price: undefined,
  currency: undefined,
  notes: undefined,
  batchTypeId: undefined,
  nicheTypeId: undefined,
  tagIds: [],
};

export const backlinkBatchFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  tagIds: yup.string(),
  notes: yup.string(),
  price: yup.number(),
  currency: yup.string(),
});

type BacklinkBatchFormProps<T> = {
  form?: BacklinkBatchFormData;
} & BaseFormProps<T>;

const BacklinkBatchForm: React.FC<BacklinkBatchFormProps<BacklinkBatchFormData>> = ({
  form,
  onSubmit,
}) => {
  const {
    control: batchControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<BacklinkBatchFormData>({
    defaultValues: form || backlinkBatchFormDefaultValues,
    resolver: yupResolver(backlinkBatchFormDataSchema),
    mode: 'onChange',
  });
  const [error, setError] = useState<boolean>(false);
  const domain = useAppSelector(selectDomain);
  const [selectedTags, setSelectedTags] = useState<string[]>();

  const handleAddTags = (items: SelectOption[]) => {
    const tags: string[] = items.map((x) => {
      return x.value;
    });
    setSelectedTags(tags);
  };

  const handleFormSubmit = () => {
    const formData = getValues();

    if (formData.name) {
      const batchDetails: BacklinkBatchFormData = {
        id: form?.id,
        domainId: domain?.id ?? '',
        name: formData.name,
        tagIds: selectedTags,
        batchTypeId: formData.batchTypeId,
        notes: formData.notes,
        price: formData.price,
        currency: formData.currency,
        nicheTypeId: formData.nicheTypeId,
      };
      onSubmit(batchDetails);
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 8000);
    }
  };

  return (
    <Flex width={'full'}>
      <Stack spacing={4} width={'full'}>
        <BacklinkBatchFormFields
          batchControl={batchControl}
          errors={errors}
          setValue={setValue}
          handleAddTags={handleAddTags}
          defaultBatchTypeId={form?.batchTypeId}
          defaultBatchNicheTypeId={form?.nicheTypeId}
          defaultCurrency={form?.currency}
          defaultTags={form?.tagIds}
        />
        <AppButton onClick={handleFormSubmit}>Save</AppButton>
        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Missing the batch name! - Please add a batch name & try again.</AlertTitle>
          </Alert>
        )}
      </Stack>
    </Flex>
  );
};

export default BacklinkBatchForm;
