import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Notification, OrganizationUser } from '@backlinkit/shared';
import { notificationApi } from '../api/notificationsApi';
import { userApi } from '../api/userApi';

interface UserState {
  userNotifications: Notification[];
  organizationUsers: OrganizationUser[];
}

const initialState: UserState = {
  userNotifications: [],
  organizationUsers: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationApi.endpoints.fetchNotificationByUser.matchFulfilled,
      (state, { payload }) => {
        return { ...state, userNotifications: payload };
      }
    );
    builder.addMatcher(
      userApi.endpoints.fetchUsersByOrganizationId.matchFulfilled,
      (state, { payload }) => {
        return { ...state, organizationUsers: payload };
      }
    );
  },
  reducers: {},
});

export const {} = userSlice.actions;

export const selectUserNotifications = (state: RootState) => state.user.userNotifications;
export const selectOrganizationUsers = (state: RootState) => state.user.organizationUsers;
