import { useBreakpointValue } from '@chakra-ui/react';
import { createContext, useContext } from 'react';

const BreakpointContext = createContext({
  textVariant: '',
  screenSize: '',
});

export function BreakpointProvider({ children }: any) {
  const textVariant = useBreakpointValue({
    base: '12px',
    md: '15px',
    lg: '18px',
  });
  const screenSize = useBreakpointValue({
    base: '1000px',
    xl: '1440px',
  });

  const value = {
    textVariant: textVariant ?? '',
    screenSize: screenSize ?? '',
  };

  return (
    <BreakpointContext.Provider value={value}>
      {children}
    </BreakpointContext.Provider>
  );
}

export function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (!context) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
}
