import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  FormLabel,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import BackLinkCreateForm from './backlink-create-form';
import MultiBackLinkCreateForm from './multi-backlink-create-form';
import CsvBackLinkCreateForm from './csv-backlink-create-form';
import { BacklinkFormData } from '@backlinkit/shared';
import BatchDropDownComponent from '../batch-dropdown-component';
import BaacklinkTagDropDownComponent from '../backlink-tag-dropdown-component';

type BacklinkCreateFormsWrapperProps = {
  domainId: string;
  isBatchCreate?: boolean;
  onSubmit: (
    data: BacklinkFormData[],
    batchId?: string,
    batchPrice?: number,
    tags?: string[]
  ) => void;
};

const BacklinkCreateFormsWrapper: React.FC<BacklinkCreateFormsWrapperProps> = ({
  domainId,
  isBatchCreate = false,
  onSubmit,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>();

  const [error, setError] = useState<boolean>(false);
  const [selectedBatchId, setSelectedBatchId] = useState<string | undefined>(undefined);
  const [batchPrice, setBatchPrice] = useState<number>();

  const handleMultiSubmit = (multiFormData: BacklinkFormData[]) => {
    if (selectedBatchId || isBatchCreate) {
      if (batchPrice) {
        onSubmit(multiFormData, selectedBatchId, batchPrice, selectedTags);
      } else {
        onSubmit(multiFormData, selectedBatchId, undefined, selectedTags);
      }
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 8000);
    }
  };

  return (
    <Flex flexDirection={'column'} flex={1}>
      <Tabs isFitted variant="enclosed" isLazy>
        <TabList mb="1em">
          <Tab>Single</Tab>
          <Tab>Multi</Tab>
          <Tab>Excel Import</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box mb={4}>
              <BaacklinkTagDropDownComponent setSelectedTags={setSelectedTags} />
            </Box>

            {!isBatchCreate && (
              <BatchDropDownComponent domainId={domainId} setSelectedBatchId={setSelectedBatchId} />
            )}
            <Box mt={4}>
              <FormLabel>Total Batch Price</FormLabel>
              <Input
                type="number"
                placeholder="Batch Price"
                onChange={(e) => {
                  setBatchPrice(+e.currentTarget.value);
                }}
              />
            </Box>
            <BackLinkCreateForm
              onSubmit={(formData) => {
                handleMultiSubmit(formData);
              }}
            ></BackLinkCreateForm>
            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Missing the batch! - Please select a batch & try again.</AlertTitle>
              </Alert>
            )}
          </TabPanel>
          <TabPanel>
            <Box mb={4}>
              <BaacklinkTagDropDownComponent setSelectedTags={setSelectedTags} />
            </Box>
            {!isBatchCreate && (
              <BatchDropDownComponent domainId={domainId} setSelectedBatchId={setSelectedBatchId} />
            )}
            <Box mt={4}>
              <FormLabel>Total Batch Price</FormLabel>
              <Input
                type="number"
                placeholder="Batch Price"
                onChange={(e) => {
                  setBatchPrice(+e.currentTarget.value);
                }}
              />
            </Box>
            <MultiBackLinkCreateForm
              onSubmit={(formData) => {
                handleMultiSubmit(formData);
              }}
            ></MultiBackLinkCreateForm>

            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Missing the batch! - Please select a batch & try again.</AlertTitle>
              </Alert>
            )}
          </TabPanel>
          <TabPanel>
            <Box mb={4}>
              <BaacklinkTagDropDownComponent setSelectedTags={setSelectedTags} />
            </Box>
            {!isBatchCreate && (
              <BatchDropDownComponent domainId={domainId} setSelectedBatchId={setSelectedBatchId} />
            )}
            <Box mt={4}>
              <FormLabel>Total Batch Price</FormLabel>
              <Input
                type="number"
                placeholder="Batch Price"
                onChange={(e) => {
                  setBatchPrice(+e.currentTarget.value);
                }}
              />
            </Box>
            <CsvBackLinkCreateForm
              onSubmit={(formData) => {
                handleMultiSubmit(formData);
              }}
            ></CsvBackLinkCreateForm>
            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Missing the batch! - Please select a batch & try again.</AlertTitle>
              </Alert>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default BacklinkCreateFormsWrapper;
