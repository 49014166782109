import {
  Image,
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  useColorModeValue,
  useDisclosure,
  useBreakpointValue,
  Icon,
  useToast,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import Logo from '../../assets/backlinkit_logo.svg';
import { ReactNode, useEffect } from 'react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { HeaderNavItems } from '../../constants/headerNav';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { FaBug, FaUserCircle, FaChalkboardTeacher } from 'react-icons/fa';
import { RoutesList } from '../../router/router';
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin, logout, selectUser } from '../../store/slices/authentication.slice';
import {
  NavItem,
  AppColors,
  AppButton,
  AppText,
  usePanel,
  BugCreateForm,
  useLoading,
  useDialog,
  Organization,
} from '@backlinkit/shared';
import { FiBell } from 'react-icons/fi';
import { FaShareAlt } from 'react-icons/fa';
import { useFetchTagsByOrganizationIdQuery } from '../../store/api/tagApi';
import {
  useFetchUsersByOrganizationIdQuery,
  useLazyFetchUserByIdQuery,
  useUserMarkSignInMutation,
} from '../../store/api/userApi';
import { useAppSelector } from '../../store/store';
import BugForm from '../forms/bug-form';
import UserInviteForm, { InviteUserForm } from '../forms/users-invite-form';
import TraingingAreaForm, { TrainingVideo } from '../forms/trainging-area';
import { useCreateBugMutation } from '../../store/api/bugReportApi';
import { useFetchNotificationByUserQuery } from '../../store/api/notificationsApi';
import OrganizationDetailForm from '../forms/organization-details-form';
import { useEditOrganizationMutation } from '../../store/api/organizationApi';
import { useSaveBulkUserInviteMutation } from '../../store/api/userInviteEmailApi';
import TrainingAreaDialog from '../forms/training-area-dialog';
import NotificationListComponent from './notification-list';
import { selectUserNotifications } from '../../store/slices/user.slice';
import { GoKebabHorizontal } from 'react-icons/go';
import { useFetchAllOrganizationExcludeLinksQuery } from '../../store/api/organizationExludeLinkApi';
import { useFetchAllOrganizationCompetitorsQuery } from '../../store/api/organization-competitorsApi';
import useSignalR from '../../hooks/useSignalR';
var packageJson = require('../../../package.json');

export type BaseLayoutProps = {
  children?: ReactNode | ReactNode[];
};

const NavLink = ({ label, href }: NavItem) => {
  return (
    <Box
      as={RouterLink}
      to={href ?? ''}
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
    >
      {label}
    </Box>
  );
};

// const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

// export const SignalRContext = createSignalRContext({ shareConnectionBetweenTab: true });

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  useSignalR();
  const version = packageJson.version;
  const toast = useToast();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  // const token = useAppSelector(selectToken);
  const notifications = useAppSelector(selectUserNotifications);

  useFetchAllOrganizationExcludeLinksQuery(user?.organizationId!, {
    refetchOnMountOrArgChange: true,
    skip: !user,
  });

  useFetchAllOrganizationCompetitorsQuery(user?.organizationId!, {
    refetchOnMountOrArgChange: true,
    skip: !user,
  });

  const isUserAdmin = useSelector(isAdmin);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const { refetch: refetchNotifications } = useFetchNotificationByUserQuery(user?.id!, {
    refetchOnMountOrArgChange: true,
  });
  const [getUserById] = useLazyFetchUserByIdQuery();
  const [updateUserSignIn] = useUserMarkSignInMutation();
  const [updateOrganization] = useEditOrganizationMutation();
  const refetchUser = async () => {
    await getUserById(user?.id ?? '');
  };
  const [reportBug] = useCreateBugMutation();
  const [inviteUsers] = useSaveBulkUserInviteMutation();

  useFetchTagsByOrganizationIdQuery(user?.organizationId || '', {
    skip: !user?.organizationId,
    refetchOnMountOrArgChange: true,
  });
  useFetchUsersByOrganizationIdQuery(user?.organizationId || '', {
    skip: !user?.organizationId,
    refetchOnMountOrArgChange: true,
  });

  const panel = usePanel();
  const dialog = useDialog();

  const onLogout = () => {
    dispatch(logout());
    navigateClick(RoutesList.Index);
  };

  const navigateClick = async (route: RoutesList) => {
    navigate(route);
  };

  const getHeaderNavItems = () => {
    if (isUserAdmin) {
      return HeaderNavItems;
    } else {
      return HeaderNavItems.filter((x) => x.isAdmin === false);
    }
  };

  // const handleDelete = async (item: Notification) => {
  //   try {
  //     const deletePromise = deleteNotification(item.id).unwrap();
  //     await deletePromise;
  //   } catch (err) {
  //     toast({
  //       title: 'Notification Delete Error',
  //       description: `We've run into a problem deleting the selected notification. Contact support for help`,
  //       status: 'success',
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //   }

  //   toast({
  //     title: 'Notification deleted',
  //     description: `We've deleted the notification you selected`,
  //     status: 'success',
  //     duration: 9000,
  //     isClosable: true,
  //   });

  //   refetchNotifications();
  // };

  const handleOrganizationExtraInfo = () => {
    panel({
      title:
        'Did you know? 75% of users who take just 2 minutes to complete these questions experience better results due to tailored features and services.',
      size: 'lg',
      showClose: false,
      showOverlay: true,
      render: (onSubmit) => {
        return (
          <OrganizationDetailForm
            onSubmit={async (formData) => {
              onSubmit();
              if (user && user.organization) {
                const organization: Organization = {
                  ...user?.organization,
                  organizationBudgetTypeId: formData.organizationBudgetTypeId,
                  organizationLinkBuildingStratergiesTypeId:
                    formData.organizationLinkBuildingStratergiesTypeId,
                  organizationSEOExperienceTypeId: formData.organizationSEOExperienceTypeId,
                  organizationTypeId: formData.organizationTypeId,
                  createdUserId: user.id,
                };

                try {
                  setLoading(true);
                  await updateOrganization(organization);
                  await refetchUser();

                  toast({
                    title: 'We have successfully updated your details!',
                    description: `Enjoy Backlinkit!`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });

                  if (!user?.hasSignedIn) {
                    handleTrainingAreaHelp();
                    markUserSignedIn();
                  }

                  setLoading(false);
                } catch (error) {
                  setLoading(false);

                  toast({
                    title: 'We ran into a problem saving your selections',
                    description: `Please try again!`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                }
              }
            }}
          />
        );
      },
    });
  };

  const showNotifications = () => {
    panel({
      title: 'Notifications',
      size: 'lg',
      render: () => {
        return <NotificationListComponent refetchNotifications={() => refetchNotifications()} />;
      },
    });
  };

  useEffect(() => {
    if (user && user.organization) {
      if (!user.organization.organizationTypeId) {
        handleOrganizationExtraInfo();
      }
    }
  }, []);

  // useEffect(() => {
  //   if (!user?.hasSignedIn) {
  //     handleTrainingAreaHelp();
  //     markUserSignedIn();
  //   }
  // }, []);

  // SignalRContext.useSignalREffect(
  //   'backLinkData',
  //   (backlinkId: string) => {
  //     if (backlinkId) {
  //       dispatch(updateTempBacklinkList(backlinkId));
  //     }
  //   },
  //   []
  // );

  // SignalRContext.useSignalREffect(
  //   'backLinkGoogleData',
  //   (backlinkId: string) => {
  //     if (backlinkId) {
  //       dispatch(updateTempBacklinkGoogleList(backlinkId));
  //     }
  //   },
  //   []
  // );

  // SignalRContext.useSignalREffect(
  //   'userCheckerLinkData',
  //   (userCheckerLinkId: string) => {
  //     if (userCheckerLinkId) {
  //       dispatch(updateTempGoogleCheckerList(userCheckerLinkId));
  //     }
  //   },
  //   []
  // );

  // SignalRContext.useSignalREffect(
  //   'updateOrganization',
  //   (userId: string) => {
  //     if (userId) {
  //       refetchUser();
  //     }
  //   },
  //   []
  // );

  // SignalRContext.useSignalREffect(
  //   'userNotification',
  //   (userId: string) => {
  //     if (userId) {
  //       refetchNotifications();
  //     }
  //   },
  //   []
  // );

  const upsertBug = async (formData: BugCreateForm) => {
    setLoading(true);
    try {
      await reportBug({
        name: formData.name,
        description: formData.description,
        screenshot: formData.screenshot ?? '',
        organizationId: user?.organizationId!,
        userId: user?.id!,
      }).unwrap();
      toast({
        title: 'Your bug has been reported',
        description: `We've created a ticket for your bug and are working on it. Come back to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'We ran into a problem reporting your bug',
        description: `We've run into a problem reporting the bug you found. Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const handleBugCreate = () => {
    panel({
      title: 'Report a bug',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <BugForm
            onSubmit={async (formData) => {
              console.log('BUG ITEM', formData);
              await upsertBug(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleShareProduct = () => {
    panel({
      title: 'Share the product!',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <UserInviteForm
            onSubmit={async (formData) => {
              await handleInviteUsers(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleInviteUsers = async (formData: InviteUserForm) => {
    try {
      setLoading(true);
      const result = await inviteUsers({
        fromName: formData.displayName,
        emails: formData.emails,
        organizationId: user?.organizationId!,
        userId: user?.id!,
        referrerMessage: formData.referrerMessage,
      }).unwrap();

      setLoading(false);

      if (result.invitedList) {
        toast({
          title: 'We have successfully sent the invite!',
          description: `We've created an email for ${result.invitedList.length} amount that you provided.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      if (result.alreadyExistList.length > 0) {
        toast({
          title: 'We noticed a few emails that have already registered!',
          description: `We've found ${result.alreadyExistList.length} emails that already exist in our system. These have not been sent invites.`,
          status: 'info',
          duration: 9000,
          isClosable: true,
        });
      }
      if (result.emailFailedList.length > 0) {
        toast({
          title: 'We noticed a few issue emails!',
          description: `We had issues sending emails to ${result.emailFailedList.length} amount that you provided`,
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: 'We ran into a problem reporting your bug',
        description: `We've run into a problem reporting the bug you found. Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const testVideos: TrainingVideo[] = [
    {
      title: 'Change Password',
      url: '/training-videos/Backlinkit - Change Password - 15 April 2024.mp4',
    },
    {
      title: 'External Reports',
      url: '/training-videos/Backlinkit - External Report Flow - 15 April 2024.mp4',
    },
    {
      title: 'Tags',
      url: '/training-videos/Backlinkit - Single Tag CRUD - 15 April 2024.mp4',
    },
    {
      title: 'Tags Bulk Create',
      url: '/training-videos/Backlinkit - Tags Bulk Create - 15 April 2024.mp4',
    },
    {
      title: 'Backlink Batches',
      url: '/training-videos/Backlinkit - Batches - 15 April 2024.mp4',
    },
    {
      title: 'Domains',
      url: '/training-videos/Backlinkit - Domains CRUD - 15 April 2024.mp4',
    },
    {
      title: 'Backlinks Single and Multi',
      url: '/training-videos/Backlinkit - Backlink Single and Multi Create - 15 April 2024.mp4',
    },
    {
      title: 'Import Backlink List',
      url: '/training-videos/Backlinkit - Backlink Excel Import - 15 April 2024.mp4',
    },
    {
      title: 'Single and Multi Google Checker',
      url: '/training-videos/Backlinkit - Google Checker Single and Multi - 15 April 2024.mp4',
    },
    {
      title: 'Import Google Checker List',
      url: '/training-videos/Backlinkit - Google Checker CSV - 15 April 2024.mp4',
    },
    {
      title: 'Users',
      url: '/training-videos/Backlinkit - Users CRUD - 15 April 2024.mp4',
    },
  ];

  const handleTrainingArea = () => {
    panel({
      title: 'Traingin Area',
      size: 'xl',
      render: () => {
        return <TraingingAreaForm videos={testVideos ?? []} />;
      },
    });
  };

  const handleTrainingAreaHelp = () => {
    dialog({
      title: 'Find our Training Area',
      size: '3xl',
      showCancel: true,
      render: (onSubmit) => {
        return <TrainingAreaDialog />;
      },
    });
  };

  const markUserSignedIn = async () => {
    await updateUserSignIn(user!.id);
    refetchUser();
  };

  return (
    <>
      {variant === 'mobile' && (
        <Alert status="warning">
          <AlertIcon />
          <AppText fontWeight={'600'}>
            This application is currently optimized for desktop use. Some features may not function
            as expected on mobile devices. We appreciate your understanding and are actively working
            on a stable mobile version.
          </AppText>
        </Alert>
      )}
      <Box bg={'white'} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ xl: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <Image
                src={Logo}
                alt=""
                height={'50px'}
                width={variant === 'desktop' ? 'auto' : '120px'}
              />
            </Box>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', xl: 'flex' }}>
              {getHeaderNavItems()?.map((link) => (
                <NavLink key={link.href} label={link.label} href={link.href} />
              ))}
            </HStack>
          </HStack>

          <Flex alignItems={'center'}>
            {variant === 'desktop' && (
              <AppButton
                bgColor={AppColors.secondary2}
                textColor={'white'}
                size={'sm'}
                mr="4"
                variant={'ghost'}
                isDisabled={true}
                // onClick={() => {
                //   handlePlanSelect();
                // }}
              >
                <AppText fontSize={'12px'}>{user?.organization?.product?.name}</AppText>
              </AppButton>
            )}

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={'flex-end'}
              alignItems={'center'}
              direction={'row'}
              spacing={variant === 'desktop' ? 6 : 2}
              zIndex={999}
            >
              <Box position={'relative'}>
                <Box
                  display={notifications?.length! > 0 ? 'flex' : 'none'}
                  pos={'absolute'}
                  top={'15%'}
                  right={'15%'}
                  height={'10px'}
                  width={'10px'}
                  borderRadius={'100%'}
                  bgColor={AppColors.secondary}
                  zIndex={1}
                />
                <IconButton
                  size="lg"
                  variant="ghost"
                  aria-label="open menu"
                  icon={<FiBell />}
                  onClick={() => showNotifications()}
                />
              </Box>

              <IconButton
                bg={AppColors.secondary}
                border={`1px solid ${AppColors.appBorder}`}
                color={'white'}
                aria-label="bug create form button"
                isRound={true}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                onClick={handleShareProduct}
              >
                <Icon as={FaShareAlt} color={'white'} />
              </IconButton>

              <Menu>
                <MenuButton as={Button} size="sm" variant={'link'} cursor={'pointer'} minW={0}>
                  <IconButton
                    size={'lg'}
                    variant="ghost"
                    aria-label="open menu"
                    color={'#222222'}
                    icon={<GoKebabHorizontal />}
                    isRound={true}
                    transform={'rotate(90deg)'}
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => navigateClick(RoutesList.UserProfile)}>
                    <IconButton
                      mr={4}
                      bg={AppColors.secondary}
                      border={`1px solid ${AppColors.appBorder}`}
                      color={'white'}
                      aria-label="column visibility button"
                      isRound={true}
                    >
                      <Icon as={FaUserCircle} boxSize={'1em'} color={'white'} />
                    </IconButton>
                    Profile
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => handleTrainingArea()}>
                    <IconButton
                      mr={4}
                      bg={AppColors.secondary}
                      border={`1px solid ${AppColors.appBorder}`}
                      color={'white'}
                      aria-label="training area"
                      isRound={true}
                    >
                      <Icon as={FaChalkboardTeacher} boxSize={'1em'} color={'white'} />
                    </IconButton>
                    Training
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => handleBugCreate()}>
                    <IconButton
                      mr={4}
                      bg={AppColors.secondary}
                      border={`1px solid ${AppColors.appBorder}`}
                      color={'white'}
                      aria-label="bug create form button"
                      isRound={true}
                    >
                      <Icon as={FaBug} color={'white'} />
                    </IconButton>
                    Bug Report
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={onLogout}>Sign Out</MenuItem>
                  <MenuDivider />
                  <AppText fontSize={'small'} pr={2} textAlign={'end'}>
                    Version: {version}
                  </AppText>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ xl: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {getHeaderNavItems()?.map((link) => (
                <NavLink key={link.href} label={link.label} href={link.href} />
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Flex
        bg={AppColors.appBackground}
        id="base-layout-container"
        overflowY={'auto'}
        overflowX={'hidden'}
        w={'100%'}
        h={'100%'}
      >
        {children}
      </Flex>
    </>
  );
};

export default BaseLayout;
