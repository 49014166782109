import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Flex,
  FlexProps,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tooltip,
} from '@chakra-ui/react';

import { useEffect } from 'react';
import usePagination from '../../hooks/usePagination';
import { PageChangeEvent, Pagination } from '../../models';
import { AppColors } from '../../theme';
import { AppText } from '../app-text';

export type AppPaginatorProps = {
  pagination?: Pagination;
  onPageChange: (event: PageChangeEvent) => void;
} & FlexProps;

export const AppPaginator: React.FC<AppPaginatorProps> = ({
  pagination = { page: 1, pageSize: 10, pageCount: 1, total: 0 },
  bgColor = AppColors.secondary,
  onPageChange,
  ...props
}) => {
  const {
    currentPage,
    currentPageSize,
    currentPageCount,
    goToNextPage,
    goToPreviousPage,
    goToFirstPage,
    goToLastPage,
    setPage,
    canGoPreviousPage,
    canGoNextPage,
    currentPageSizes,
    setPageSize,
    setNewPaginationProps,
  } = usePagination({
    page: pagination.page,
    pageSize: pagination.pageSize,
    pageCount: pagination.pageCount,
    total: pagination.total,
  });

  useEffect(() => {
    setNewPaginationProps(pagination);
  }, [pagination]);

  useEffect(() => {
    // console.log('currentPage, currentPageSize', currentPage, currentPageSize);
    // console.log('currentPage, pagiination', pagination);

    onPageChange({
      currentPage: currentPage,
      currentPageSize: currentPageSize,
      currentPageCount: currentPageCount,
    });
  }, [currentPage, currentPageSize]);

  return (
    <Flex position={'relative'} justifyContent="space-between" m={4} alignItems="center" {...props}>
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            onClick={() => goToFirstPage()}
            isDisabled={!canGoPreviousPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
            aria-label={'First Page'}
            bg={AppColors.secondary}
            color={'white'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            onClick={goToPreviousPage}
            isDisabled={!canGoPreviousPage}
            icon={<ChevronLeftIcon h={6} w={6} />}
            aria-label={'Previous Page'}
            bg={AppColors.secondary}
            color={'white'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <AppText flexShrink="0" mr={8}>
          Page{' '}
          <AppText fontWeight="bold" as="span">
            {currentPage}
          </AppText>{' '}
          of{' '}
          <AppText fontWeight="bold" as="span">
            {currentPageCount}
          </AppText>
        </AppText>
        {/* <AppText flexShrink="0">Go to page:</AppText>
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={currentPageCount}
            onChange={(value) => {
              const valueParsed = parseInt(value);
              if (!Number.isNaN(valueParsed)) {
                console.log('setpage', valueParsed);
                setPage(Number(valueParsed));
              }
            }}
            defaultValue={currentPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput> */}
        <Select
          w={32}
          value={currentPageSize}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (!Number.isNaN(value)) {
              setPageSize(value);
            }
          }}
        >
          {currentPageSizes.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            onClick={goToNextPage}
            isDisabled={!canGoNextPage}
            icon={<ChevronRightIcon h={6} w={6} />}
            aria-label={'Next Page'}
            bg={AppColors.secondary}
            color={'white'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            onClick={() => goToLastPage()}
            isDisabled={!canGoNextPage}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
            aria-label={'Last Page'}
            bg={AppColors.secondary}
            color={'white'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
