import { Base, PaginationFilter, SortParam } from './base';

export interface UserGoogleCheckerLink extends Base {
  url: string;
  cacheState?: boolean;
  cacheCreatedDate?: Date;
  hasDomainIndex?: boolean;
  hasPageIndex?: boolean;
  domainCreatedDate?: string;
  userId: string;
  checkGoogleIndex?: boolean;
  checkGoogleCache?: boolean;
  checkDomainAge?: boolean;
  domainAgeStatus?: DomainAgeStatus;
}

export interface UserCheckerLinkBulkCreateRequest {
  checkGoogleIndex: boolean;
  checkGoogleCache: boolean;
  checkDomainAge: boolean;
  userCheckerLinks: UserGoogleCheckerLinkCreate[];
}

export interface UserCheckerLinkReprocessRequest {
  checkGoogleIndex: boolean;
  checkGoogleCache: boolean;
  checkDomainAge: boolean;
  userCheckerLinkIds: string[];
}

export interface UserGoogleCheckerLinkCreate {
  url: string;
  userId: string;
}

export interface UserCheckerLinksByOptionsRequest extends PaginationFilter {
  userId: string;
  sortParams?: SortParam[];
}

export enum DomainAgeStatus {
  SUCCESS,
  ERROR,
  NOAGE,
}
