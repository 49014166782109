import React, { useEffect, useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import {
  AppInput,
  SelectOption,
  SelectListFilter,
  AppButton,
  AppColors,
  useDialog,
} from '@backlinkit/shared';
import { Box, Flex, FormLabel, IconButton, SimpleGrid, Tooltip } from '@chakra-ui/react';
import { BacklinkBatchFormData } from './backlink-batch-form';
import { useFetchAllBatchTypesQuery } from '../../store/api/batchTypesApi';
import { useFetchAllNicheTypesQuery } from '../../store/api/nicheTypeApi';
import { RiSettings5Fill } from 'react-icons/ri';
import TagManagement from '../dialog-management/tag-management';
import { useAppSelector } from '../../store/store';
import { selectOrganizationTags } from '../../store/slices/tag.slice';
import { useCurrencies } from '../../hooks/useCurrencies';

type BacklinkBatchFormFieldsProps = {
  batchControl: Control<BacklinkBatchFormData, any>;
  errors: FieldErrors<BacklinkBatchFormData>;
  setValue: any;
  defaultBatchTypeId?: string;
  defaultBatchNicheTypeId?: string;
  defaultTags?: string[];
  defaultCurrency?: string;
  handleAddTags: (items: SelectOption[]) => void;
};

const BacklinkBatchFormFields: React.FC<BacklinkBatchFormFieldsProps> = ({
  batchControl,
  errors,
  setValue,
  defaultBatchTypeId,
  defaultBatchNicheTypeId,
  defaultTags,
  defaultCurrency,
  handleAddTags,
}) => {
  const dialog = useDialog();
  const tags = useAppSelector(selectOrganizationTags);
  const [currentTags, setCurrentTags] = useState<SelectOption[]>([]);
  const [currentDefaultTags, setCurrentDefaultTags] = useState<SelectOption[]>([]);
  const [batchNicheTypeOptions, setBatchNicheTypeOptions] = useState<SelectOption[]>();
  const [batchTypeOptions, setBatchTypeOptions] = useState<SelectOption[]>();
  const currencies = useCurrencies();
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>();

  const { data: batchNicheTypes } = useFetchAllNicheTypesQuery({
    refetchOnMountArgChange: true,
  });
  const { data: batchTypes } = useFetchAllBatchTypesQuery({
    refetchOnMountArgChange: true,
  });

  useEffect(() => {
    if (tags) {
      const selectors: SelectOption[] = tags.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setCurrentTags(selectors);
    }
  }, [tags]);

  useEffect(() => {
    if (batchNicheTypes) {
      const selectors: SelectOption[] = [];
      batchNicheTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setBatchNicheTypeOptions(selectors);
    }
  }, [batchNicheTypes]);

  useEffect(() => {
    if (batchTypes) {
      const selectors: SelectOption[] = [];
      batchTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setBatchTypeOptions(selectors);
    }
  }, [batchTypes]);

  useEffect(() => {
    if (defaultTags && currentTags) {
      const defaultValues = currentTags.filter((x) => defaultTags.includes(x.value));
      setCurrentDefaultTags(defaultValues);
      handleAddTags(defaultValues);
    }
  }, [defaultTags, currentTags]);

  const getDefaultBatchTypeOption = () => {
    if (batchTypeOptions && defaultBatchTypeId) {
      const defaultValue = batchTypeOptions.find((x) => x.value === defaultBatchTypeId);

      return defaultValue;
    }

    return undefined;
  };

  const getDefaultBatchNicheTypeOption = () => {
    if (batchNicheTypeOptions && defaultBatchNicheTypeId) {
      const defaultValue = batchNicheTypeOptions.find((x) => x.value === defaultBatchNicheTypeId);

      return defaultValue;
    }

    return undefined;
  };

  const getDefaultCurrencyOption = () => {
    if (currencyOptions && defaultCurrency) {
      const defaultValue = currencyOptions.find((x) => x.value === defaultCurrency);

      return defaultValue;
    }

    return undefined;
  };

  // const getDefaultTagOptions = () => {
  //   if (tags && defaultTags) {
  //     const defaultValues = currentTags.filter((x) => defaultTags.includes(x.value));

  //     return defaultValues;
  //   }

  //   return undefined;
  // };

  const handleTagManagement = () => {
    dialog({
      title: `Tag Management`,
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <TagManagement />;
      },
    });
  };

  useEffect(() => {
    setCurrencyOptions(currencies);
  }, [currencies]);

  return (
    <>
      <AppInput<BacklinkBatchFormData>
        name="name"
        control={batchControl}
        error={errors.name}
        label="Batch Name"
      />
      <Flex alignItems={'end'}>
        <Box flex={1}>
          <FormLabel mb={2} p={0}>
            Tags
          </FormLabel>
          <SelectListFilter
            mr={2}
            name="batchTags"
            options={currentTags}
            isInModal
            isMulti
            defaultOption={currentDefaultTags}
            onSelectionChange={(items: SelectOption[]) => {
              handleAddTags(items);
            }}
          />
        </Box>

        <Tooltip shouldWrapChildren label={'Manage Tags'} aria-label="manageTags">
          <AppButton
            px={3}
            bgColor={AppColors.appBackground}
            color={'black'}
            onClick={() => handleTagManagement()}
          >
            <RiSettings5Fill />
          </AppButton>
        </Tooltip>
      </Flex>

      <FormLabel p={0} m={0}>
        Batch Type
      </FormLabel>
      <Flex align={'center'} width={'100%'} gap={'10px'}>
        <SelectListFilter
          name="batchType"
          options={batchTypeOptions}
          isInModal
          showClear={true}
          defaultOption={getDefaultBatchTypeOption()}
          onSelectionChange={(items: SelectOption[]) => {
            if (items) {
              setValue('batchTypeId', items[0]?.value);
            } else {
              setValue('batchTypeId', undefined);
            }
          }}
          width={'100%'}
        />
      </Flex>
      <FormLabel m={0} p={0}>
        Batch Niche
      </FormLabel>
      <Flex align={'center'} width={'100%'} gap={'10px'}>
        <SelectListFilter
          name="batchNicheType"
          options={batchNicheTypeOptions}
          isInModal
          showClear={true}
          defaultOption={getDefaultBatchNicheTypeOption()}
          onSelectionChange={(items: SelectOption[]) => {
            if (items) {
              setValue('nicheTypeId', items[0]?.value);
            } else {
              setValue('nicheTypeId', undefined);
            }
          }}
          w={'100%'}
        />
      </Flex>
      <AppInput<BacklinkBatchFormData>
        name="notes"
        control={batchControl}
        error={errors.notes}
        label={'Notes'}
      />
      <SimpleGrid columns={2} columnGap={4} pb={4}>
        <AppInput<BacklinkBatchFormData>
          name="price"
          error={errors.price}
          control={batchControl}
          label={'Batch Price'}
        />
        <Flex height={'100%'} align={'end'}>
          <SelectListFilter
            flex={1}
            mr={2}
            options={currencyOptions || []}
            isInModal
            isMulti={false}
            showClear={true}
            placeholder="Select Currency"
            defaultOption={getDefaultCurrencyOption()}
            onSelectionChange={(item: SelectOption[]) => {
              if (item) {
                setValue('currency', item[0]?.value);
              } else {
                setValue('currency', undefined);
              }
            }}
          />
          <AppButton
            size={'xs'}
            marginBottom={'10px'}
            onClick={() => {
              setValue('price', 0);
            }}
          >
            Clear Price
          </AppButton>
        </Flex>
      </SimpleGrid>
    </>
  );
};

export default BacklinkBatchFormFields;
