import React, { useEffect } from 'react';
import { Box, Flex, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { AppLoader, AppText, getSumOfNumbers } from '@backlinkit/shared';
import { useState } from 'react';
import { DomainStats } from '../dashboard/dashboard';
import UsersTable from '../../components/app/app-organization-users/organizationUsers';
import Subscription from '../dashboard/subscription';
import { selectDomains } from '../../store/slices/domain.slice';
import usePayments from '../../hooks/usePayments';
import AppTabs from '@backlinkit/shared/lib/components/app-tabs/app-tabs';
import { format } from 'date-fns';
import CompetitorTable from '../../components/app/app-organization-competitors/app-organization-competitors';
import ExcludeLinksTable from '../../components/app/app-organization-exclude-links/app-organziation-exclude-links';

export const OrganizationPage: React.FC = () => {
  const user = useSelector(selectUser);
  const organization = user?.organization;
  const domains = useSelector(selectDomains);
  const [domainStats, setDomainStats] = useState<DomainStats>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  const { handlePlanSelect } = usePayments();

  // const dateSubscribed = new Date(organization?.product?.dateCreated ?? '')
  //   .toUTCString()
  //   .slice(0, 16);

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  useEffect(() => {
    if (domains) {
      const backLinkCountList = domains.map((x) => x.backlinkCount);
      const errorCountList = domains.map((x) => x.backlinkErrorCount);
      const domainStatsData = {
        domainCount: domains.length,
        linkCount: getSumOfNumbers(backLinkCountList),
        errorCount: getSumOfNumbers(errorCountList),
      };

      setDomainStats(domainStatsData);
    }
  }, [domains]);

  const panelTitles = [
    'Users',
    'Competitors',
    'EFL Blacklist',
    'Transactions (Coming Soon)',
    'Invoices (Coming Soon)',
  ];

  return (
    <Box p={'20px'} w="100%">
      {isLoading && <AppLoader />}
      <Box display={variant === 'desktop' ? 'grid' : 'flex'} flexDir={'column'} h="100%" w="100%">
        <GridItem
          rowSpan={variant === 'desktop' ? 1 : 2}
          rowStart={1}
          gridColumnStart={variant === 'desktop' ? 1 : 1}
          gridColumnEnd={variant === 'desktop' ? 4 : 8}
          maxH={'180px'}
          pos={'relative'}
          mr={4}
        >
          <Flex
            bgColor={'white'}
            flexDir={'column'}
            w={'full'}
            h={'full'}
            borderRadius={'2xl'}
            p={6}
            align={'center'}
          >
            {/* <Flex
              w={'100%'}
              align={'center'}
              justify={'end'}
              pos={'absolute'}
              top={4}
              right={4}
              zIndex={5}
            >
              <Icon
                _hover={{
                  color: AppColors.secondary,
                }}
                as={EditIcon}
                cursor={'pointer'}
                onClick={() => handlePlanSelect()}
              />
            </Flex> */}
            <Subscription
              used={domainStats ? domainStats.linkCount : 0}
              total={user?.organization?.product?.amount ?? 0}
            />
          </Flex>
        </GridItem>
        <GridItem
          rowSpan={variant === 'desktop' ? 1 : 2}
          rowStart={variant === 'desktop' ? 1 : 3}
          gridColumnStart={variant === 'desktop' ? 4 : 1}
          gridColumnEnd={13}
          maxH={'180px'}
        >
          <Flex
            direction={'column'}
            borderRadius={'2xl'}
            bgColor={'white'}
            p={6}
            h={'100%'}
            justify={'space-between'}
          >
            <AppText fontSize={'2xl'} fontWeight={'bold'}>
              Billing Details
            </AppText>
            <Flex justify={'space-evenly'} align={'center'}>
              <Flex flexDir={'column'} w={'full'} justify={'space-between'} gap={'15px'} h={'100%'}>
                <AppText>Profile Created Date</AppText>
                <AppText fontWeight={'bold'}>
                  {organization?.dateCreated
                    ? format(new Date(organization?.dateCreated), 'dd MMM yyyy')
                    : ''}
                </AppText>
              </Flex>
              <Flex flexDir={'column'} w={'full'} justify={'space-between'} gap={'15px'} h={'100%'}>
                <AppText>Plan</AppText>
                <AppText fontWeight={'bold'}>{user?.organization.product?.name}</AppText>
              </Flex>
              <Flex flexDir={'column'} w={'full'} justify={'space-between'} gap={'15px'} h={'100%'}>
                <AppText>Payment</AppText>
                <AppText fontWeight={'bold'}>{`$${organization?.product?.price}`}</AppText>
              </Flex>
              <Flex flexDir={'column'} w={'full'} justify={'space-between'} gap={'15px'} h={'100%'}>
                <AppText>Available Credit</AppText>
                <AppText fontWeight={'bold'}>{`${organization?.availableCredit}`}</AppText>
              </Flex>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem
          mt={4}
          rowSpan={variant === 'desktop' ? 11 : 7}
          colSpan={{ sm: 12, md: 12, lg: 12 }}
          bgColor={'white'}
          p={4}
          rounded={'2xl'}
        >
          <AppTabs
            bgColor={'white'}
            rounded={'2xl'}
            // h={'65vh'}
            overflow={'auto'}
            p={4}
            mb={{ base: '0px', '2xl': '20px' }}
            titles={panelTitles}
            tabs={[<UsersTable />, <CompetitorTable />, <ExcludeLinksTable />]}
          />
          {/* <TransactionsTable />, <InvoicesTable /> */}
        </GridItem>
      </Box>
    </Box>
  );
};
