import { Icon, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { AppColors } from '@backlinkit/shared';

type LastModifiedCellProps = {
  lastModified?: string;
  lastModifiedDate?: Date;
};

const LastModifiedCell: React.FC<LastModifiedCellProps> = ({ lastModified, lastModifiedDate }) => {
  if (lastModifiedDate) {
    const lmDate = format(new Date(lastModifiedDate), 'dd MMM yyyy hh:mm:ss a O');

    return (
      <Tooltip label={`Last modified - ${lmDate} by ${lastModified ?? 'System Schedule'}`}>
        <span>
          <Icon as={FaUserCircle} height={'20px'} width={'20px'} color={AppColors.secondary} />
        </span>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

export default LastModifiedCell;
