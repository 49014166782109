import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Flex,
  FlexProps,
  Table,
  Icon,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SettingsIcon } from '@chakra-ui/icons';
import { backlinkURLRegex, domainURLRegex } from '../../../constants/regex';
import { selectDomain } from '../../../store/slices/domain.slice';

import {
  useFetchBacklinksByIdsQuery,
  useLazyFetchCheckerBacklinksByOptionsQuery,
} from '../../../store/api/backlinksApi';
import {
  backLinkMultiFormDataDefaultValues,
  MultiBacklinkFormData,
  multiBackLinkFormDataSchema,
} from '../../forms/multi-backlink-create-form';
import {
  backlinkImportFileValidation,
  validateMultiBacklinkFormData,
} from '../../../utils/sharedFormHelpers';
import { FiFile } from 'react-icons/fi';
import { FaDownload } from 'react-icons/fa';
import { format } from 'date-fns';
import {
  AppButton,
  AppColors,
  AppCsvFileUploadReader,
  AppInput,
  AppText,
  BackLink,
  BackLinkListItem,
  BacklinkCSVImport,
  CsvFileUploadReaderResult,
  LinkCheckerData,
  TrackedStatus,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import CheckerDomainTable from './tables/domain-table';
import CheckerLinkCard from './tables/link-card';
import { useAppSelector } from '../../../store/store';

const { JSONtoCSVConverter } = require('react-json-csv-convert');

// export enum TrackedStatus {
//   TRACKED = 'Tracked',
//   DOMAIN_TRACKED = 'Domain Tracked',
//   NOT_TRACKED = 'Not Tracked',
// }

// export interface AppLinkCheckerData {
//   url: string;
//   backlinks: BackLink[];
//   status: TrackedStatus;
// }

export type AppLinkChecker = {} & FlexProps;

export const appLinkCheckerDefaultValues: LinkCheckerData = {
  url: '',
  backlinks: [],
  status: TrackedStatus.NOT_TRACKED,
};

const appLinkCheckerDataSchema = yup.object({
  url: yup.string().required('Field is required').matches(backlinkURLRegex, 'Not a valid Url'),
});

const AppLinkChecker: React.FC<AppLinkChecker> = ({ ...props }) => {
  const dialog = useDialog();
  const { setLoading } = useLoading();
  const domain = useAppSelector(selectDomain);
  const [selectedBacklinksIds, setSelectedBacklinksIds] = useState<string[]>([]);

  const { data: selectedBacklinksData, refetch: refetchBacklinksbyIds } =
    useFetchBacklinksByIdsQuery(selectedBacklinksIds, {
      skip: selectedBacklinksIds.length === 0,
      refetchOnMountOrArgChange: true,
    });

  const [getCheckerBacklinks] = useLazyFetchCheckerBacklinksByOptionsQuery();

  const [urlList, setUrlList] = useState<LinkCheckerData[]>([]);
  const [selectedLink, setSelectedLink] = useState<LinkCheckerData>();
  const [selectedBacklinks, setSelectedBacklinks] = useState<BackLinkListItem[]>([]);

  useEffect(() => {
    if (selectedBacklinksIds.length > 0) {
      refetchBacklinksbyIds();
    }
  }, [selectedBacklinksIds]);

  useEffect(() => {
    if (selectedBacklinksData && selectedBacklinksData.length > 0) {
      setSelectedBacklinks(selectedBacklinksData);
    }
  }, [selectedBacklinksData]);

  const {
    control: backLinkControl,
    formState: { isValid, errors },
    getValues,
    reset,
  } = useForm<LinkCheckerData>({
    defaultValues: appLinkCheckerDefaultValues,
    resolver: yupResolver(appLinkCheckerDataSchema),
    mode: 'onChange',
  });

  const {
    control: multiBackLinkControl,
    formState: { isValid: isMultiValid, errors: multiErrors },
    getValues: getMultiFormValues,
    reset: multiFormReset,
  } = useForm<MultiBacklinkFormData>({
    defaultValues: backLinkMultiFormDataDefaultValues,
    resolver: yupResolver(multiBackLinkFormDataSchema),
    mode: 'onChange',
  });

  const handleFormSubmit = () => {
    const currentForm = getValues();
    updateUrlList([currentForm.url]);
    reset();
  };

  const updateUrlList = async (urls: string[]) => {
    try {
      setLoading(true);
      const data = await getCheckerBacklinks({ domainId: domain?.id ?? '', urls }).unwrap();
      if (data.linkCheckerData) {
        const urlListCopy = [...urlList, ...data.linkCheckerData];
        setUrlList(urlListCopy);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    // urls.forEach((url) => {
    //   if (url) {
    //     const urlObject = new URL(url);
    //     urlDomain = urlObject.hostname;
    //   }

    //   // TODO - CHANGE THIS TO AN API CALL
    //   // const directBacklink = domain?.backlinks?.find((x) => x.url === url);

    //   // const includedBacklinks = domain?.backlinks?.filter(
    //   //   (x) => urlDomain && x.url.includes(urlDomain)
    //   // );

    //   // if (includedBacklinks && includedBacklinks.length > 0) {
    //   //   let status = directBacklink ? TrackedStatus.TRACKED : TrackedStatus.DOMAIN_TRACKED;
    //   //   const urlListIndex = urlListCopy.findIndex((x) => x.url === url);
    //   //   if (urlListIndex === -1) {
    //   //     urlListCopy.push({
    //   //       url: url,
    //   //       backlinks: includedBacklinks,
    //   //       status,
    //   //     });
    //   //   }
    //   // } else if (directBacklink) {
    //   //   const urlListIndex = urlListCopy.findIndex((x) => x.url === directBacklink.url);
    //   //   if (urlListIndex === -1) {
    //   //     urlListCopy.push({
    //   //       url: directBacklink.url,
    //   //       backlinks: [directBacklink],
    //   //       status: TrackedStatus.TRACKED,
    //   //     });
    //   //   }
    //   // } else {
    //   //   const urlListIndex = urlListCopy.findIndex((x) => x.url === url);
    //   //   if (urlListIndex === -1) {
    //   //     urlListCopy.push({
    //   //       url: url,
    //   //       backlinks: [],
    //   //       status: TrackedStatus.NOT_TRACKED,
    //   //     });
    //   //   }
    //   // }
    // });
  };

  const handleLinkDetailsClick = (link: LinkCheckerData) => {
    const ids = link.backlinks?.map((x) => {
      return x.id;
    });
    if (ids.length > 0) {
      setSelectedBacklinksIds(ids);
    }
    setSelectedLink(link);
  };

  const clearList = () => {
    setUrlList([]);
    setSelectedBacklinksIds([]);
    setSelectedBacklinks([]);
    setSelectedLink(undefined);
  };

  const getTrackedStatusColorScheme = (trackedStatus: TrackedStatus) => {
    switch (trackedStatus) {
      case TrackedStatus.TRACKED:
        return 'green';
      case TrackedStatus.DOMAIN_TRACKED:
        return 'orange';
      case TrackedStatus.NOT_TRACKED:
        return 'red';
    }
  };

  const handleMultiFormSubmit = () => {
    const currentForm = getMultiFormValues();
    const backlinksData = validateMultiBacklinkFormData(currentForm.urls);
    console.log(backlinksData);
    const validUrls = backlinksData
      .filter((x) => x.valid === true)
      .map((x) => {
        return x.url;
      });
    updateUrlList(validUrls);

    multiFormReset();
  };

  const onBacklinkImportFileParsed = (fileData: CsvFileUploadReaderResult) => {
    const backlinksFormList = backlinkImportFileValidation(fileData);
    if (backlinksFormList && backlinksFormList.validbacklinkFormsData.length > 0) {
      const validUrls = backlinksFormList.validbacklinkFormsData.map((x) => {
        return x.url;
      });
      updateUrlList(validUrls);
    }
  };

  const handleExportCsvClicked = () => {
    const exportData = urlList?.map((item) => {
      return {
        url: item.url || '',
        status: item.status || '',
        backlinkCount: item.backlinks ? item.backlinks.length : 0,
      };
    });

    const headers = ['URL', 'STATUS', 'BACKLINK COUNT'];
    const csvConfig = {
      headers,
      actions: Object.keys(headers).map((x) => null),
      keys: ['url', 'status', 'backlinkCount'],
    };

    dialog({
      title: 'Export Link Checker list',
      render: (onSubmit, onCancel) => {
        return (
          <JSONtoCSVConverter
            csvConfig={csvConfig}
            data={exportData}
            styleProp={{ display: 'inline-block' }}
            fileName={`backlinkit-checker-export-${format(new Date(), 'dd MMM yyyy')}`}
          >
            <AppButton
              borderRadius={'full'}
              mr="4"
              onClick={() => {
                setupExportLoader();
                onCancel();
              }}
            >
              Download CSV
            </AppButton>
          </JSONtoCSVConverter>
        );
      },
    });
  };

  const setupExportLoader = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleExport = () => {
    const exportData = selectedBacklinks?.map((backLink) => {
      console.log('LInk', backLink);
      return {
        domain: domain?.url ?? '',
        url: backLink.url ?? '',
        landingPage: backLink.landingPage ?? '',
        anchor: backLink.anchor ?? 'Not Found',
        relStatus: backLink.backlinkMeta?.relStatus ?? '',
        dateAdded: backLink.dateCreated ?? '',
        expectedLandingPage:
          backLink.expectedLandingPage?.length && backLink.expectedLandingPage.length > 0
            ? backLink.expectedLandingPage
            : `${backLink.url + backLink.landingPage}`,
        expectedAnchor: backLink.expectedAnchor ?? '',
        expectedRel: backLink.expectedRelValue ?? '',
        hasPageIndex: backLink.backlinkGoogle?.hasPageIndex ?? '',
        hasDomainIndex: backLink.backlinkGoogle?.hasDomainIndex ?? '',
        tldCountry: backLink.backlinkMeta?.tldCountry ?? '',
        tldDomain: backLink.backlinkMeta?.tldDomain ?? '',
        tldSubDomain: backLink.backlinkMeta?.tldSubDomain ?? '',
        ip: backLink.backlinkMeta?.ip ?? '',
        ipCountry: backLink.backlinkMeta?.ipCountry ?? '',
        externalNoFollowCount: backLink.backlinkMeta?.externalNoFollowCount ?? '',
        externalFollowCount: backLink.backlinkMeta?.externalFollowCount ?? '',
        internalFollowCount: backLink.backlinkMeta?.internalFollowCount ?? '',
      };
    });

    const headers = [
      'DOMAIN',
      'URL',
      'LANDING PAGE',
      'ANCHOR',
      'REL',
      'DATE ADDED',
      'EXPECTED LANDING PAGE',
      'EXPECTED ANCHOR',
      'EXPECTED REL',
      'GOOGLE PAGE INDEX',
      'GOOGLE DOMAIN INDEX',
      'TLD COUNTRY',
      'TLD DOMAIN',
      'TLD SUB DOMAIN',
      'IP',
      'IP COUNTRY',
      'EXTERNAL NO FOLLOW COUNT',
      'EXTERNAL FOLLOW COUNT',
      'INTERNAL FOLLOW COUNT',
    ];
    const csvConfig = {
      headers,
      actions: Object.keys(headers).map((x) => null),
      keys: [
        'domain',
        'url',
        'landingPage',
        'anchor',
        'relStatus',
        'dateAdded',
        'expectedLandingPage',
        'expectedAnchor',
        'expectedRel',
        'hasPageIndex',
        'hasDomainIndex',
        'tldCountry',
        'tldDomain',
        'tldSubDomain',
        'ip',
        'ipCountry',
        'externalNoFollowCount',
        'externalFollowCount',
        'internalFollowCount',
      ],
    };

    dialog({
      title: 'Export Backlinks',
      render: (onSubmit, onCancel) => {
        return (
          <JSONtoCSVConverter
            csvConfig={csvConfig}
            data={exportData}
            styleProp={{ display: 'inline-block' }}
            fileName={`backlink-export-${format(new Date(), 'dd MMM yyyy')}`}
          >
            <AppButton
              mr="4"
              onClick={() => {
                setupExportLoader();
                onCancel();
              }}
            >
              Download CSV
            </AppButton>
          </JSONtoCSVConverter>
        );
      },
    });
  };

  return (
    <Flex
      flexDirection={'row'}
      flex={1}
      gap={5}
      {...props}
      minH={'450px'}
      justifyContent={'space-between'}
    >
      <Card
        flex={1}
        variant="filled"
        bg={'white'}
        rounded={'2xl'}
        mb="20px"
        p="20px"
        overflow="auto"
      >
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <AppText fontWeight="bold" fontSize="2xl" mt="10px" mb={4}>
            Backlink Checker
          </AppText>
          <AppButton
            variant={'solid'}
            size="sm"
            borderRadius="full"
            isDisabled={urlList && urlList.length === 0}
            onClick={() => {
              handleExportCsvClicked();
            }}
          >
            {'Export List'}
          </AppButton>
        </Flex>

        <form>
          <Flex flexDir={'column'}>
            <Flex flexDirection={'column'} flex={1}>
              <Tabs isFitted variant="enclosed">
                <TabList>
                  <Tab>Single</Tab>
                  <Tab>Multi</Tab>
                  <Tab>CSV Import</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Flex
                      flexDir={'row'}
                      justifyContent={'space-between'}
                      alignItems={'end'}
                      w={'full'}
                    >
                      <AppInput<LinkCheckerData>
                        control={backLinkControl}
                        w={'50%'}
                        name="url"
                        label="Domain / Url"
                        error={errors.url}
                        placeHolder={'Domain / Url'}
                      />
                      <Flex alignItems={'center'}>
                        <AppButton
                          variant={'solid'}
                          size="sm"
                          borderRadius="full"
                          alignSelf={'flex-end'}
                          isDisabled={!isValid}
                          mr={4}
                          onClick={() => {
                            handleFormSubmit();
                          }}
                        >
                          {'Add'}
                        </AppButton>

                        <AppButton
                          variant={'solid'}
                          size="sm"
                          borderRadius="full"
                          alignSelf={'flex-end'}
                          isDisabled={urlList && urlList.length === 0}
                          onClick={() => {
                            clearList();
                          }}
                        >
                          {'Clear Checker'}
                        </AppButton>
                      </Flex>
                    </Flex>
                  </TabPanel>
                  <TabPanel>
                    <Flex
                      flexDir={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      w={'full'}
                    >
                      <AppInput<MultiBacklinkFormData>
                        w={'50%'}
                        control={multiBackLinkControl}
                        name="urls"
                        label="URLs"
                        error={multiErrors.urls}
                        placeHolder={'Urls'}
                        textArea={true}
                      />
                      <Flex alignItems={'center'}>
                        <AppButton
                          variant={'solid'}
                          size="sm"
                          borderRadius="full"
                          alignSelf={'flex-end'}
                          isDisabled={!isMultiValid}
                          mr={4}
                          onClick={() => {
                            handleMultiFormSubmit();
                          }}
                        >
                          {'Validate and Add'}
                        </AppButton>
                        <AppButton
                          variant={'solid'}
                          size="sm"
                          borderRadius="full"
                          alignSelf={'flex-end'}
                          isDisabled={urlList && urlList.length === 0}
                          onClick={() => {
                            clearList();
                          }}
                        >
                          {'Clear Checker'}
                        </AppButton>
                      </Flex>
                    </Flex>
                  </TabPanel>
                  <TabPanel>
                    <Flex
                      flexDir={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      w={'full'}
                      py={4}
                    >
                      <Flex flexDir={'row'} w={'50%'}>
                        <AppCsvFileUploadReader<BacklinkCSVImport>
                          onFileParsed={onBacklinkImportFileParsed}
                        >
                          <AppButton
                            borderRadius={'full'}
                            variant={'solid'}
                            size={'sm'}
                            leftIcon={<Icon as={FiFile} />}
                          >
                            Upload
                          </AppButton>
                        </AppCsvFileUploadReader>
                      </Flex>
                      <Flex alignItems={'center'}>
                        <Link href={'/templates/csv-import-template.csv'} target="_blank" mr={4}>
                          <AppButton
                            variant={'solid'}
                            size="sm"
                            borderRadius="full"
                            alignSelf={'flex-end'}
                            leftIcon={<Icon as={FaDownload} />}
                          >
                            Download Template
                          </AppButton>
                        </Link>
                        <AppButton
                          variant={'solid'}
                          size="sm"
                          borderRadius="full"
                          alignSelf={'flex-end'}
                          isDisabled={urlList && urlList.length === 0}
                          onClick={() => {
                            clearList();
                          }}
                        >
                          {'Clear Checker'}
                        </AppButton>
                      </Flex>
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
            <TableContainer flex={1}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Domain / Url</Th>
                    <Th>Domain Count</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {urlList.map((link, index) => {
                    return (
                      <Tr key={`${link.url}-${index}`}>
                        <Td>
                          <Tooltip label={link.url} fontWeight={'bold'}>
                            <Box>
                              <AppText maxW={'200px'} isTruncated>
                                {link.url}
                              </AppText>
                            </Box>
                          </Tooltip>
                        </Td>
                        <Td>
                          <AppText isTruncated>
                            {link.backlinks ? link.backlinks.length : 0}
                          </AppText>
                        </Td>
                        <Td>
                          <Flex
                            flexDir={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Tag
                              borderRadius="full"
                              colorScheme={getTrackedStatusColorScheme(link.status)}
                            >
                              <TagLabel>
                                {link.status === TrackedStatus.TRACKED && 'Link Tracked'}
                                {link.status === TrackedStatus.DOMAIN_TRACKED && 'Domain Tracked'}
                                {link.status === TrackedStatus.NOT_TRACKED && 'Not Tracked'}
                              </TagLabel>
                            </Tag>

                            {link.status !== TrackedStatus.NOT_TRACKED && (
                              <AppButton
                                mr={4}
                                bgColor={AppColors.secondary}
                                variant={'solid'}
                                color={'white'}
                                size="xs"
                                borderRadius="full"
                                onClick={() => {
                                  handleLinkDetailsClick(link);
                                }}
                                rightIcon={<SettingsIcon />}
                              >
                                Details
                              </AppButton>
                            )}
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        </form>
      </Card>
      <Card
        overflow={'auto'}
        flex={1}
        variant="filled"
        bg={'white'}
        rounded={'2xl'}
        mb="20px"
        p="20px"
      >
        <Flex width={'100%'} align={'center'} justify={'space-between'}>
          <AppText fontWeight="bold" fontSize="2xl" mt="10px" mb={4}>
            {selectedLink?.url} Details
          </AppText>
          <AppButton onClick={handleExport}>Export</AppButton>
        </Flex>

        {selectedLink?.status === TrackedStatus.DOMAIN_TRACKED ? (
          <CheckerDomainTable links={selectedBacklinks} />
        ) : (
          <>
            {selectedLink && (
              <CheckerLinkCard selectedLink={selectedLink} links={selectedBacklinks} />
            )}
          </>
        )}
      </Card>
    </Flex>
  );
};

export default AppLinkChecker;
