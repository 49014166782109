import { selectToken, selectUser } from '../store/slices/authentication.slice';
import { useAppSelector } from '../store/store';
import { useLazyFetchUserByIdQuery } from '../store/api/userApi';
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFetchNotificationByUserQuery } from '../store/api/notificationsApi';
import {
  updateTempBacklinkList,
  updateTempBacklinkGoogleList,
} from '../store/slices/backlink.slice';
import { updateTempGoogleCheckerList } from '../store/slices/domain.slice';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';

const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

const useSignalR = () => {
  const dispatch = useDispatch();
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const [getUserById] = useLazyFetchUserByIdQuery();

  const connectionRef = useRef<HubConnection>();

  const { refetch: refetchNotifications } = useFetchNotificationByUserQuery(user?.id!, {
    skip: !user?.id,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!connectionRef.current) {
      if (token && user && user.id) {
        connectionRef.current = new HubConnectionBuilder()
          .withUrl(`${SERVER_URL}/notifications`)
          .withAutomaticReconnect()
          .build();

        setupConnection(connectionRef.current, user.id);
      }

      return () => {
        if (connectionRef.current && connectionRef.current.state === HubConnectionState.Connected) {
          console.log('Stopping signalR connection');
          connectionRef.current.stop();
          connectionRef.current = undefined;
        }
      };
    }
  }, [token, user]);

  // useEffect(() => {
  //   window.addEventListener('focus', function () {
  //     if (
  //       user &&
  //       connectionRef &&
  //       connectionRef.current &&
  //       connectionRef.current.state !== HubConnectionState.Connected
  //     ) {
  //       debugger;
  //       connectionRef.current.invoke('Subscribe', user.id);
  //       //setupConnection(connectionRef.current, user.id);
  //     }
  //   });
  // }, []);

  const refetchUser = async () => {
    await getUserById(user?.id ?? '');
  };

  const setupConnection = async (connection: HubConnection, userId: string) => {
    console.log('Starting connection', userId);

    await connection.start();

    if (userId) {
      console.log('Invoking subscribe', userId);
      connection.invoke('Subscribe', userId);
    }

    connection.onreconnected(() => {
      if (userId) {
        console.log('Invoking subscribe', userId);
        connection.invoke('Subscribe', userId);
      }
    });

    connection.on('backLinkData', (data) => {
      console.log('backLinkData recieved', data);
      if (data) {
        dispatch(updateTempBacklinkList(data));
      }
    });

    connection.on('backLinkGoogleData', (data) => {
      console.log('backLinkGoogleData recieved', data);
      if (data) {
        dispatch(updateTempBacklinkGoogleList(data));
      }
    });
    connection.on('userCheckerLinkData', (data) => {
      console.log('userCheckerLinkData recieved', data);
      if (data) {
        dispatch(updateTempGoogleCheckerList(data));
      }
    });
    connection.on('updateOrganization', (data) => {
      console.log('updateOrganization recieved', data);
      if (data) {
        refetchUser();
      }
    });
    connection.on('userNotification', (data) => {
      console.log('userNotification recieved', data);
      if (data) {
        refetchNotifications();
      }
    });
  };
};

export default useSignalR;
