import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { OrganizationNewFeatureTypeDto } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationNewFeatureTypeApi = createApi({
  reducerPath: 'organizationNewFeatureTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationNewFeatureTypes: build.query<OrganizationNewFeatureTypeDto[], any>({
      query: () => ({
        url: '/organizationNewFeatureType',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchAllOrganizationNewFeatureTypesQuery } = organizationNewFeatureTypeApi;
