import { Flex, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import BaseLayout from '../../components/layouts/base-layout';
import {
  useDeleteBacklinkMutation,
  useEditBacklinkMutation,
  useBacklinkBulkRescrapeMutation,
  useBacklinkRescrapeGoogleMutation,
  useBacklinkRescrapeMutation,
  useLazyFetchSecondTierBacklinksByLinkIdQuery,
  useSaveBacklinkMutation,
} from '../../store/api/backlinksApi';
import { selectUser } from '../../store/slices/authentication.slice';
import { selectDomain } from '../../store/slices/domain.slice';
import { useAppSelector } from '../../store/store';
import { useEffect, useState } from 'react';
import {
  resetTempBacklinkList,
  selectBacklink,
  selectTempBacklinkList,
} from '../../store/slices/backlink.slice';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { SettingsIcon } from '@chakra-ui/icons';
import BacklinksTable, { BacklinkMenuItems } from '../../components/backlinks/backlinks-table';
import DomainLayout from '../../components/layouts/domain-layout';
import {
  AppConfirm,
  AppPaginator,
  BackLink,
  BackLinkListItem,
  BackLinkUpdateBulkRequest,
  BacklinkBulkScrapeItem,
  BacklinkFilter,
  BacklinkFormData,
  BacklinkQueueModel,
  BacklinkTypes,
  ColumnDefinitionType,
  PageChangeEvent,
  PaginatedData,
  Pagination,
  ResponseMethodTypes,
  SearchParam,
  SortParam,
  sortingColumnsToSortingArray,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import { useCreateBatchMutation } from '../../store/api/backlinkBatchesApi';

const SecondTierLinks: React.FC = () => {
  const title = 'Second Tier Links';
  const { setLoading } = useLoading();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backlink = useAppSelector(selectBacklink);
  const domain = useAppSelector(selectDomain);
  const tempBacklinks = useAppSelector(selectTempBacklinkList);
  const [tier2Backlinks, setTier2Backlinks] = useState<PaginatedData<BackLinkListItem>>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [currentPageSize, setCurrentPageSize] = useState<number>();
  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);
  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();

  const [backlinkFilter, setBacklinkFilter] = useState<BacklinkFilter>();

  const [pagination, setPagination] = useState<Pagination>();

  const [createBatchTrigger] = useCreateBatchMutation();

  const [deleteBacklinkTrigger, { isLoading: isDeletingBacklink }] = useDeleteBacklinkMutation();

  const [saveBacklinkTrigger, { isLoading: isAddBacklinkSaving }] = useSaveBacklinkMutation();

  const [editBacklinkTrigger, { isLoading: isEditBacklinkSaving }] = useEditBacklinkMutation();

  const [getScrapedBacklinkData, { isLoading: isBacklinkScrapeLoading }] =
    useBacklinkRescrapeMutation();

  const [getScrapedBacklinkGoogleData] = useBacklinkRescrapeGoogleMutation();

  const [postBacklinkBulkUpdate] = useBacklinkBulkRescrapeMutation();

  const [getBacklinksByLinkId] = useLazyFetchSecondTierBacklinksByLinkIdQuery();

  const user = useAppSelector(selectUser);

  const toast = useToast();

  useEffect(() => {
    if (currentPage && currentPageSize) {
      setupBacklinks(
        currentPage,
        currentPageSize,
        currentSortList,
        currentSearchParam,
        backlinkFilter
      );
    }
  }, [currentPage, currentPageSize]);

  useEffect(() => {
    if (tier2Backlinks && tier2Backlinks.pagination) {
      console.log('dashboardDomains?.pagination ', tier2Backlinks);
      setPagination(tier2Backlinks?.pagination);

      setCurrentPage(tier2Backlinks.pagination?.page || 1);
      setCurrentPageSize(tier2Backlinks.pagination?.pageSize || 10);
    }
  }, [tier2Backlinks]);

  const setupBacklinks = async (
    page: number | undefined,
    pageSize: number | undefined,
    sortList: SortParam[] | undefined,
    searchParam: SearchParam | undefined,
    backlinkFilter: BacklinkFilter | undefined
  ) => {
    console.log('setupBacklinks');
    setLoading(true);
    setTier2Backlinks(undefined);
    const backlinks = await getBacklinksByLinkId(backlink?.id || '').unwrap();

    setTier2Backlinks(backlinks);
    setLoading(false);
  };

  const addBacklink = async (backlinkFormsData: BacklinkFormData[]) => {
    const edittedBacklink: boolean =
      backlinkFormsData.length === 1 && backlinkFormsData[0].id ? true : false;

    try {
      if (!user) {
        toast({
          title: 'User account not found.',
          description: 'Sign out and back in.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }
      setLoading(true);

      for (const backlinkFormData of backlinkFormsData) {
        if (!backlinkFormData.id) {
          const newBacklinkEntry = await saveBacklinkTrigger({
            url: backlinkFormData.url,
            expectedLandingPage: backlinkFormData.expectedLandingPage,
            expectedAnchor: backlinkFormData.expectedAnchor,
            notes: backlinkFormData.notes,
            domainId: domain?.id || '',
            parentId: backlink?.id,
            expectedRelValue: backlinkFormData.expectedRel,
            backlinkType: BacklinkTypes.tierTwo,
          }).unwrap();

          await getScrapedBacklinkData({
            backlinkId: newBacklinkEntry.id || '',
            parentUrl: backlink?.url || '',
          });
          // getScrapedBacklinkGoogleData({
          //   backlinkId: newBacklinkEntry.id || '',
          // });
        } else {
          const backlink = tier2Backlinks?.data?.find((x) => x.id === backlinkFormData.id);

          if (backlink) {
            // const editBacklinkEntry = await editBacklinkTrigger({
            //   ...backlink,
            //   url: backlinkFormData.url,
            //   expectedLandingPage: backlinkFormData.expectedLandingPage,
            //   expectedAnchor: backlinkFormData.expectedAnchor,
            //   notes: backlinkFormData.notes,
            //   expectedRelValue: backlinkFormData.expectedRel,
            // }).unwrap();
          }
        }
      }

      setLoading(false);
      toast({
        title: `Backlink ${!edittedBacklink ? 'added' : 'updated'}.`,
        description: `We've  ${
          !edittedBacklink ? 'added' : 'updated'
        }. your backlink for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      setupBacklinks(
        currentPage,
        currentPageSize,
        currentSortList,
        currentSearchParam,
        backlinkFilter
      );
    } catch (error) {
      toast({
        title: `Backlink  ${!edittedBacklink ? 'add' : 'update'}. error.=`,
        description: `We've run into a problem  ${
          !edittedBacklink ? 'adding' : 'updating'
        }. your backlink, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  const handleBackButton = () => {
    navigate(RoutesList.Dashboard);
  };

  const handleBacklinkRescrape = async (backlinkId: string) => {
    setLoading(true);

    getScrapedBacklinkData({
      backlinkId,
      parentUrl: backlink?.url || '',
    });

    toast({
      title: 'Processing Complete',
      description: "We've reprocessed your backlink data",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setLoading(false);
  };

  const handleBacklinksRescrape = async (selectedBacklinks: BackLinkListItem[]) => {
    setLoading(true);

    let backlinks: BacklinkBulkScrapeItem[] = [];

    selectedBacklinks.forEach((x) => {
      if (x.backlinkMeta?.backlinkResponse?.methodType !== ResponseMethodTypes.ExternalApi) {
        backlinks.push({
          backlinkId: x.id,
          parentUrl: backlink?.url,
          url: x.url,
          expectedLandingPage: x.expectedLandingPage,
          expectedAnchor: x.expectedAnchor,
          expectedRel: x.expectedRelValue,
          backlinkType: x.backlinkType,
          isManual: x.isManual,
        } as BacklinkBulkScrapeItem);
      }
    });
    const data: BackLinkUpdateBulkRequest = {
      backlinks: backlinks,
    };

    postBacklinkBulkUpdate(data);

    toast({
      title: 'Processing Complete',
      description: "We've reprocessed your backlinks data",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setupBacklinks(
      currentPage,
      currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );

    setLoading(false);
  };

  const handleBacklinkExternalReport = async (selectedBacklinks: BackLinkListItem[]) => {
    setLoading(true);

    // const refreshes = selectedBacklinks.map((x) =>
    //   getScrapedBacklinkData(x.id)
    // );

    // await Promise.all(refreshes);

    toast({
      title: 'Processing Complete',
      description: "We've reprocessed your backlinks data",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    // await refetchBacklinks();

    setLoading(false);
  };

  const handleDeleteBacklinkClicked = (selectedBacklinks: BackLinkListItem[]) => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedBacklinks.length} selected Backlinks?`}
            onConfirm={async () => {
              onSubmit();

              await handleDeleteBacklinks(selectedBacklinks);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDeleteBacklinks = async (backlinks: BackLink[]) => {
    try {
      const deletePromises = backlinks.map((backlink) =>
        deleteBacklinkTrigger(backlink.id).unwrap()
      );

      await Promise.all(deletePromises);
    } catch (e) {
      toast({
        title: 'Backlink Delete error.',
        description:
          "We've run into a problem deleting the selected backlinks, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Backlinks deleted.',
      description: "We've deleted the backlinks you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    setupBacklinks(
      currentPage,
      currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<BackLinkListItem>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setCurrentSortList(columnSortQueryString);
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    setCurrentPage(pageChangeEvent.currentPage);
    setCurrentPageSize(pageChangeEvent.currentPageSize);
  };

  const handleSearchChange = (searchParam: SearchParam) => {
    setCurrentSearchParam(searchParam);
  };

  const handleFiltersChange = (filter: BacklinkFilter) => {
    setBacklinkFilter(filter);
  };

  const menuItems: BacklinkMenuItems[] = [
    {
      icon: <SettingsIcon />,
      onClick: (item) => handleBacklinkRescrape(item.id),
      label: 'Reprocess-link',
    },
  ];

  const additionalBackLinkColumns: ColumnDefinitionType<BackLinkListItem>[] = [];

  const refreshBacklinks = async () => {
    setLoading(true);
    dispatch(resetTempBacklinkList());
    await setupBacklinks(
      currentPage,
      currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );
    setLoading(false);
  };

  return (
    <DomainLayout>
      <Flex px={6} py={2} flex={1} direction="column" width={'100vw'}>
        {/* <BacklinksTable
          title={title}
          parentUrl={backlink?.url || ''}
          data={tier2Backlinks?.data || []}
          tempBacklinkCount={tempBacklinks && tempBacklinks.length}
          additionalBacklinkColumns={additionalBackLinkColumns}
          additionalMenuItems={menuItems}
          isBatchCreateDisabled={true}
          onAddClick={addBacklink}
          onSearchChange={handleSearchChange}
          onSortByColumnClick={handleSortByColumnClick}
          onFiltersChange={handleFiltersChange}
          onSelectedBacklinksRescrape={handleBacklinksRescrape}
          onSelectedBacklinksDelete={handleDeleteBacklinkClicked}
          onSelectedBacklinksExternalReport={handleBacklinkExternalReport}
          onBackButtonClick={handleBackButton}
          refetch={() => refreshBacklinks()}
        >
          <AppPaginator
            pagination={pagination}
            onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
          ></AppPaginator>
        </BacklinksTable> */}
      </Flex>
    </DomainLayout>
  );
};

export default SecondTierLinks;
