import { Box, Flex, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AppText } from '@backlinkit/shared';
import { BacklinkTag } from '@backlinkit/shared/lib/models/backlinkTag';

type LinkTagsCellProps = { backlinkTags?: BacklinkTag[] };

const LinkTagsCell: React.FC<LinkTagsCellProps> = ({ backlinkTags }) => {
  if (backlinkTags && backlinkTags.length > 0) {
    return (
      <>
        {
          <Box justifyContent={'center'}>
            <Tooltip
              label={
                <Flex flexDirection={'column'}>
                  {backlinkTags &&
                    backlinkTags.length > 0 &&
                    backlinkTags.map((backlinkTag, index) => (
                      <AppText key={`link-tag-${index}-${backlinkTag?.tag?.id}`}>
                        {backlinkTag?.tag?.name}
                      </AppText>
                    ))}
                </Flex>
              }
            >
              {backlinkTags && backlinkTags.length > 0 && (
                <Tag borderRadius="full" colorScheme={'gray'}>
                  <TagLabel>{backlinkTags[0].tag?.name}</TagLabel>
                </Tag>
              )}
            </Tooltip>
          </Box>
        }
      </>
    );
  } else {
    return (
      <Tag borderRadius="full">
        <TagLabel>-</TagLabel>
      </Tag>
    );
  }
};

export default LinkTagsCell;
