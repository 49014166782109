import { Box, Card, GridItem } from '@chakra-ui/react';
import avatar from '../../assets/Profile_avatar_placeholder_large.png';
import OrganizationProfile from './organization-profile';
import Subscription from './subscription';
import { useFetchFeaturedFaqsQuery } from '../../store/api/faqApi';
import Domains from '../../components/domains/domains';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { selectDomains } from '../../store/slices/domain.slice';
import { useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { AppButton, AppColors, getSumOfNumbers } from '@backlinkit/shared';
import usePayments from '../../hooks/usePayments';
import { useLazyFetchDomainsByOrgIdQuery } from '../../store/api/domainApi';

export type DomainStats = {
  domainCount: number;
  linkCount: number;
  errorCount: number;
};

const Dashboard: React.FC = () => {
  const user = useSelector(selectUser);
  const domains = useSelector(selectDomains);
  const [getDomainsByOrgId] = useLazyFetchDomainsByOrgIdQuery();
  const { handlePlanSelect } = usePayments();

  const [domainStats, setDomainStats] = useState<DomainStats>();
  const { data: faqs } = useFetchFeaturedFaqsQuery({ refetchOnMountArgChange: true });
  useEffect(() => {
    if (user && user.organizationId) {
      getDomainsByOrgId(user.organizationId);
    }
  }, [user]);

  useEffect(() => {
    if (domains) {
      const backLinkCountList = domains.map((x) => x.backlinkCount);
      const errorCountList = domains.map((x) => x.backlinkErrorCount);
      const domainStatsData = {
        domainCount: domains.length,
        linkCount: getSumOfNumbers(backLinkCountList),
        errorCount: getSumOfNumbers(errorCountList),
      };

      setDomainStats(domainStatsData);
    }
  }, [domains]);

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  return (
    <Box p={'20px'} w="100%">
      <Box
        display={variant === 'desktop' ? 'grid' : 'flex'}
        flexDir={'column'}
        h="100%"
        w="100%"
        gap={4}
      >
        <GridItem
          rowSpan={1}
          gridColumnStart={1}
          gridColumnEnd={variant === 'desktop' ? 4 : 13}
          w={'100%'}
        >
          <OrganizationProfile
            avatar={avatar}
            name={`${user?.email}` || ''}
            subTitle={user?.organization?.name || ''}
            domains={domainStats ? domainStats.domainCount : 0}
            links={domainStats ? domainStats.linkCount : 0}
            errors={domainStats ? domainStats.errorCount : 0}
          />
        </GridItem>
        <GridItem
          rowSpan={variant === 'desktop' ? 1 : 3}
          gridColumnStart={variant === 'desktop' ? 4 : 1}
          gridColumnEnd={variant === 'desktop' ? 13 : 13}
          display={'flex'}
          flexDir={'column'}
          bgColor={'white'}
          p={4}
          rounded={'2xl'}
        >
          <Subscription
            h="100%"
            used={domainStats ? domainStats.linkCount : 0}
            total={user?.organization?.product?.amount ?? 0}
          />
          <AppButton
            borderRadius={'full'}
            size={'md'}
            bgColor={AppColors.secondary}
            color={'white'}
            isDisabled={true}
            // onClick={() => handlePlanSelect()}
          >
            Plans
          </AppButton>
        </GridItem>
        {/* <GridItem
          rowSpan={variant === 'desktop' ? 1 : 5}
          gridColumnStart={variant === 'desktop' ? 8 : 1}
          gridColumnEnd={13}
        >
          <FAQDisplay h="100%" p="20px" pb={{ base: '100px', lg: '20px' }} faqs={faqs || []} />
        </GridItem> */}

        <GridItem rowSpan={variant === 'desktop' ? 3 : 7} colSpan={{ sm: 12, md: 12, lg: 12 }}>
          <Card
            h="100%"
            variant="filled"
            bg={'white'}
            p="4"
            rounded={'2xl'}
            mb={{ base: '0px', '2xl': '20px' }}
          >
            <Domains />
          </Card>
        </GridItem>
      </Box>
    </Box>
  );
};

export default Dashboard;
